import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import ImageGallery from 'react-image-gallery';

import css from './ListingPage.module.css';
import ProfileImageGallery from './ProfileImageGallery/ProfileImageGallery';

const SectionGallery = props => {
  const {
    className,
    listing,
    variantPrefix,
    isMobileLayout,
    publicData,
    onManageDisableScrolling,
  } = props;

  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const projects = publicData?.projects;

  if (images?.length === 0 || projects?.length === 0) {
    return null;
  }

  const classes = classNames(className, css.sectionGallery);

  return (
    <div className={classes}>
      <h2 className={css.galleryTitle}>
        <FormattedMessage id="SectionGallery.title" />
      </h2>

      {/* <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        isMobileLayout={isMobileLayout}
        onManageDisableScrolling={onManageDisableScrolling}
      /> */}
      <ProfileImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        isMobileLayout={isMobileLayout}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

export default SectionGallery;
