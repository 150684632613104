import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, IconInquiry, Heading, SecondaryButton } from '../../../components';

import css from './InquiryForm.module.css';

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

const InquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        handleSubmit,
        onSaveCompany,
        inProgress,
        saveCompanyInProgress,
        listingTitle,
        sendInquiryError,
        saveCompanyError,
        inquirySubmitted,
        saveCompanySubmitted,
        isCurrentCompanySaved,
        isCompanyUser,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || saveCompanyInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {inquirySubmitted ? (
            <div className={css.submittedContent}>
              <Heading as="h2" rootClassName={css.heading}>
                <FormattedMessage id="InquiryForm.headingSubmitted" />
              </Heading>
              <p className={css.submittedText}>
                <FormattedMessage id="InquiryForm.submittedText" />
              </p>
            </div>
          ) : (
            <div>
              <IconInquiry className={css.icon} />
              <Heading as="h2" rootClassName={css.heading}>
                <FormattedMessage id="InquiryForm.heading" values={{ listingTitle }} />
              </Heading>
              <div className={submitButtonWrapperClassName}>
                <ErrorMessage error={sendInquiryError} />
                <div className={css.buttonsFlex}>
                  {/* {isCompanyUser ? (
                    <SecondaryButton
                      spinnerClassName={css.spinner}
                      type="button"
                      inProgress={saveCompanyInProgress}
                      disabled={submitDisabled || isCurrentCompanySaved || saveCompanySubmitted}
                      onClick={onSaveCompany}
                    >
                      {isCurrentCompanySaved || saveCompanySubmitted ? (
                        <FormattedMessage id="InquiryForm.savedCompanyButtonText" />
                      ) : (
                        <FormattedMessage id="InquiryForm.saveCompanyButtonText" />
                      )}
                    </SecondaryButton>
                  ) : null} */}
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="InquiryForm.submitButtonText" />
                  </PrimaryButton>
                </div>
              </div>
            </div>
          )}
        </Form>
      );
    }}
  />
);

InquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

InquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InquiryForm = compose(injectIntl)(InquiryFormComponent);

InquiryForm.displayName = 'InquiryForm';

export default InquiryForm;
