import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton } from '../../components';

import css from './ListingPage.module.css';

const ARCHITECTURE_KEY = 'architecture';

const findListingField = (fields, key) => fields.find(f => f.key === key)?.enumOptions;
const findExtendedDataValue = (options, valueKey) => options.find(o => o.option === valueKey);

const SectionConnect = props => {
  const {
    className,
    title,
    publicData,
    listingConfig,
    isOwnListing,
    onContactUser,
    connectionLoading,
    isConnected,
    isPendingApproval,
  } = props;
  const { listingFields } = listingConfig || {};

  const industryOptions = findListingField(listingFields, 'industry');
  const industry = findExtendedDataValue(industryOptions, publicData?.industry);

  const city = publicData?.address?.city;

  return (
    <div className={className || css.sectionConnect}>
      {/* <div className={css.connectTitleContainer}>
        <h4 className={css.connectTitle}>{title}</h4>
        <div className={css.connectSubHeading}>
          {city ? <span className={css.connectSubHeadingLocation}>{city}</span> : null}
          {industry ? (
            <span className={css.connectSubHeadingIndustry}>
              {industry.option === ARCHITECTURE_KEY ? (
                <FormattedMessage id="ListingPage.architecture" />
              ) : (
                <FormattedMessage id="ListingPage.interiorDesign" />
              )}
            </span>
          ) : null}
        </div>
      </div> */}
      {!isPendingApproval ? (
        <PrimaryButton
          type="button"
          className={css.connectButton}
          disabled={isOwnListing || isConnected}
          onClick={onContactUser}
          inProgress={connectionLoading}
        >
          <FormattedMessage id={isConnected ? 'ListingPage.connected' : 'ListingPage.connect'} />
        </PrimaryButton>
      ) : null}
    </div>
  );
};

export default SectionConnect;
